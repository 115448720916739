import React, { FC } from "react";
import { graphql, Link } from "gatsby";
import { Layout } from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";
import { NarrowContainer } from "../components/container";
import { OutlineButtonInner } from "../components/button";
// @ts-ignore
import FsLightbox from "fslightbox-react";
import styled from "styled-components";
import { unescape } from "he";
import { ProjectTemplateQuery } from "../../graphql-types";

type Props = {
  data: ProjectTemplateQuery;
};

const ProjectTemplate: FC<Props> = (props) => {
  const [lightboxController, setLightboxController] = React.useState({
    toggler: false,
    slide: 1,
    sources: [] as string[],
  });

  const [lightboxSources, updateLightboxSources] = React.useState([] as any[]);
  const [lightboxKey, updateLightboxKey] = React.useState(1);

  React.useEffect(() => {
    updateLightboxKey(lightboxKey + 1);

    updateLightboxSources(
      props.data.wpProject?.projectCustomFields?.images?.map(
        (image: any) => image.localFile.childImageSharp.fluid.src
      ) || []
    );
  }, []);

  function openLightboxOnSlide(number: number) {
    console.log(lightboxSources);
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
      sources: lightboxSources,
    });
  }

  return (
    <Layout>
      <SEO title={unescape(props.data.wpProject?.title || "")} />

      <NarrowContainer style={{ marginTop: 80, marginBottom: 100 }}>
        <Link to="/projecten/">
          <OutlineButtonInner>&larr; Terug naar projecten</OutlineButtonInner>
        </Link>

        <Title>{unescape(props.data.wpProject?.title || "")}</Title>

        <div
          dangerouslySetInnerHTML={{
            __html: props.data.wpProject?.content || "",
          }}
        />

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {props.data.wpProject?.projectCustomFields?.images?.map(
            (image: any, i: number) => (
              <div key={i} style={{ margin: 5 }}>
                <button onClick={() => openLightboxOnSlide(i + 1)}>
                  <Img fixed={image.localFile.childImageSharp.fixed} />
                </button>
              </div>
            )
          )}
        </div>
      </NarrowContainer>

      <FsLightbox
        toggler={lightboxController.toggler}
        sources={lightboxSources}
        slide={lightboxController.slide}
        key={lightboxKey}
        types={lightboxSources?.map(() => "image") || []}
      />
    </Layout>
  );
};

const Title = styled.h1`
  font-family: Poppins, sans-serif;
  color: #0b0b0b;
  font-size: 30px;
  margin-top: 0.75em;
  margin-bottom: 0.25em;
  font-weight: 100;
  line-height: 1.25;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 35px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 40px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 50px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 60px;
  }
`;

export default ProjectTemplate;

export const query = graphql`
  query ProjectTemplate($id: String!) {
    wpProject(id: { eq: $id }) {
      title
      content
      projectCustomFields {
        images {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
              fixed(width: 150, height: 150) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
